'use client'

import type { DeliveryInfoProps } from ".";

import { useEffect, useState } from "react"
import ActionBox from "@components/atoms/action-box"
import Cookies from 'js-cookie';

const DeliveryInfo = ({ message }: DeliveryInfoProps) => {

    const [swHideDeliveryInfo, setSwHideDeliveryInfo] = useState<boolean | null>(null)
    useEffect(() => setSwHideDeliveryInfo('1' === Cookies.get('swHideDeliveryInfo')), [])

    return false === swHideDeliveryInfo && (
        <ActionBox
            fluid

            key={'action_delivery_info'}
            color={'#636464'}
            variant={'warning'}
            className={'px-3 px-md-4 py-3 fw-bold justify-content-center'}

            isCloseable={true}
            isBorderless={true}
            hasIcon={false}

            onClose={() => {
                Cookies.set('swHideDeliveryInfo', '1')
                setSwHideDeliveryInfo(true)
            }}
        >
            <span className={'d-block'}>
                <span>{message}</span>
            </span>
        </ActionBox>
    )
}

export default DeliveryInfo
