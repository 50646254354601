import Link from 'next/link';
import type { IconProps } from '.';
import Image from '@components/atoms/image';
import cn from 'classnames';
import classNames from 'classnames';

const SIZE_TO_PIXEL = {
    xs: 12,
    sm: 14,
    md: 20,
    lg: 24,
    xl: 36,
    xxl: 64,
    xxxl: 156,
};

const ICONS = {
    'cart-empty': '/Icons/32px/icon-cart-empty.png',
    'user': '/Icons/32px/icon-user.png',
    'service': '/Icons/32px/icon-service.png',
    'service-dark': '/Icons/32px/icon-service-dark.svg',
    'wishlist': '/Icons/32px/icon-wishlist.png',
    'wishlist-small': '/Icons/14px/icon-wishlist.svg',
    'wishlist-small-checked': '/Icons/14px/icon-wishlist-checked.svg',
    'chevron-left': '/Icons/10px/icon-chevron-left.png',
    'chevron-right': '/Icons/10px/icon-chevron-right.png',
    'chevron-up': '/Icons/14px/icon-chevron-up.svg',
    'chevron-down': '/Icons/14px/icon-chevron-down.svg',
    'filter': '/Icons/14px/icon-filter.svg',
    'phone': '/Icons/icon-phone.svg',
    'menu': '/Icons/14px/icon-menu.svg',
    'x': '/Icons/14px/icon-x.png',
    'home': '/Icons/14px/icon-home.png',
    'compare': '/Icons/10px/icons-10-px-icon-compare.svg',
    'check': '/Icons/14px/icon-check.png',
    'close': '/Icons/14px/icon-x.png',
    'badge-aktion': '/badge/aktion.svg',
    'badge-closeout': '/badge/auslauf.svg',
    'badge-topseller': '/badge/beliebt.svg',
    'badge-new': '/badge/neu.svg',
    'badge-eco': '/badge/umweltfreundlich.svg',
    'badge-eco-1': '/badge/biologisch_abbaubar.svg',
    'badge-eco-2': '/badge/industriell_abbaubar.svg',
    'badge-eco-3': '/badge/aus_nachwachsenden rohstoffen.svg',
    'badge-eco-4': '/badge/recyclebar.svg',
    'badge-eco-5': '/badge/aus_recyclingmaterial.svg',
    'badge-eco-6': '/badge/reduzierter_materialeinsatz.svg',
    'badge-eco-8': '/badge/wiederverwendbar.svg',
    'badge-eco-umweltsignets_vegan': '/badge/vegan.svg',
    'trash': '/Icons/14px/icon-trash.png',
    'reorder': '/Icons/14px/icon-reorder.png',
    'wiki': '/Icons/wiki.svg',
    'shipping': '/Icons/icon-shipping.svg'
}

const isLink = (element: JSX.Element, href?: string) => (href ? <Link href={href}>{element}</Link> : element);

const isCircleIcon = (element: JSX.Element, circle: boolean, variant?: string, size?: string) =>
    circle ? (
        <div
            className={cn('icon-circle', {
                [`icon-circle-${variant}`]: variant != undefined,
                [`icon-${size}`]: size != undefined,
            })}
        >
            {element}
        </div>
    ) : (
        element
    );

const hasIndicatorIcon = (element: JSX.Element, hasIndicator: boolean, indicatorColor?: string) =>
    hasIndicator ? (
        <div>
            <div className={'position-relative'}>
                {element}
                <span
                    className={cn('position-absolute top-0 start-100 translate-middle rounded-circle', {
                        'bg-success': !indicatorColor,
                    })}
                    style={{
                        width: 10,
                        height: 10,
                        ...(indicatorColor && { backgroundColor: indicatorColor }),
                    }}
                ></span>
            </div>
        </div>
    ) : (
        element
    );

const Icon = ({
    size,
    name,
    variant,
    className,
    backgroundColor,
    height,
    circle = false,



    src,
    href,
    hasIndicator = false,
    indicatorColor,
    circleIconColor,
    cursor,
    ...props
}: IconProps) => {

    return (
        <span className={classNames('d-inline-flex icon', {
            'rounded-circle': circle,
            [`icon--${name}`]: name,
            [`icon--size__${size}`]: size,
            [`icon--height__${height}`]: height,
            [`${className}`]: className,
            [`bg-success`]: '#8bc003' === backgroundColor,
            [`bg-warning`]: '#ffc61b' === backgroundColor,
            [`bg-danger`]: '#dc3545' === backgroundColor
        })}></span>
    )

    /*
        { [`icon icon--${icon}`]: icon },
        { [`icon-${iconVariant}`]: iconVariant },
        { [`icon-size--${iconWidth}`]: iconWidth }

    return hasIndicatorIcon(
        isLink(
            isCircleIcon(
                <Image
                    {...props}
                    alt={props.alt ?? ''}
                    style={{ cursor: cursor ?? 'pointer' }}
                    className={cn(`icon`, {
                        'icon-white': circle && variant != 'white',
                        [`${props.className}`]: props.className,
                        [`icon-${variant}`]: variant != undefined && !circle,
                        [`icon-${circleIconColor}`]: circleIconColor != undefined && circle,
                    })}
                    src={src ?? (name && ICONS[name]) ?? ''}
                    width={size ? SIZE_TO_PIXEL[size] : 20}
                    height={'xxxl' !== size ? (size ? SIZE_TO_PIXEL[size] : 20) : 78}
                />,
                circle,
                variant,
                size
            ),
            href
        ),
        hasIndicator,
        indicatorColor
    );
    */
};

export default Icon;
