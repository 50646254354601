import { FC, PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';
import { ActionBoxProps } from '.';
import Icon from '../icon';
import classNames from 'classnames';

const ActionBox: FC<ActionBoxProps> = ({
  children,
  text,
  isBorderless = false,
  isCloseable = false,
  hasIcon = true,
  hasSpan = true,
  variant,
  color,
  onClose = () => { },
  ...props
}: PropsWithChildren<ActionBoxProps>) => {
  return (
    <Container {...props} className={classNames('position-relative d-flex p-2', {
      [`${props.className}`]: props.className,
      [`action-box--${variant}`]: variant,
      [`text-${variant}`]: variant && 'undefined' === typeof color,
      'border-0': isBorderless,
      [`border border-${variant}`]: false === isBorderless && variant
    })}
      {... (color && {
        style: { color }
      })}
    >
      {hasIcon && (
        <div className="d-flex align-items-start py-1">{
          <Icon
            size="md"
            className={`icon--${variant}`}
            name={'service'}
            alt={text ?? ''}
            title={text ?? ''}
            {... (color && { style: { color } })}
          />
        }
        </div>
      )}
      <div>
        {text !== undefined && <h6 className="Button-Small-Danger">{text}</h6>}
        {hasSpan ? <span>{children}</span> : children}
        {isCloseable && (
          <span className={'position-absolute end-0 top-0 h-100 d-flex align-items-center px-4 cursor--pointer icon icon--x  icon--dark  icon--size__md  rounded-circle'} onClick={onClose}>
          </span>
        )}
      </div>

    </Container>
  );
};

export default ActionBox;
