'use client'

import { useSession } from "next-auth/react";
import type { CustomerBoxProps } from ".";

import dynamic from "next/dynamic";

const LoginRegisterBox = dynamic(() => import("../login-register-box"));
const CustomerOrderBox = dynamic(() => import("../customer-order-box"));

const CustomerBox = ({ }: CustomerBoxProps) => {
    const { data: session } = useSession()
    return (
       (null === session || undefined === session || true === session.user?.anonym) ? <LoginRegisterBox /> : <CustomerOrderBox />
    )
}

export default CustomerBox
